/*!
 *
 *  *
 *  *  Copyright (C) THL A29 Limited, a Tencent company. All rights reserved.
 *  *  SPDX-License-Identifier: Apache-2.0
 *  *
 *
 */

.content{
  width:600px;
  margin:0 auto;
  :global(.tea-form__label){
    label,legend{
      font-size:14px;
      color:black;
    }
  }
  :global(.tea-input), :global(.tea-textarea), :global(.tea-textarea-group), :global(.tea-text-label){
    font-size: 14px;
  }
  :global(.tea-text-label){
    display: inline-block;
    width:450px;
  }
  :global(.tea-input), :global(.tea-text-label){
    height:40px;
    width:450px;
  }
  .rule{
    width:580px;
    margin: 20px auto 0;
    .rule_i{
      font-size: 14px;
      font-weight: 400;
      color: #333333;
    }
  }
}
