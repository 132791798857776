/*!
 *
 *  *
 *  *  Copyright (C) THL A29 Limited, a Tencent company. All rights reserved.
 *  *  SPDX-License-Identifier: Apache-2.0
 *  *
 *
 */

.chart{
  width:642px;
  height:420px;
  background-color: white;
}
.chart_title{
  margin:24px 20px 0 26px;
}
.chart_time{
  font-size: 14px;
  font-weight: 400;
  color: #666666;
}
.chart_c{
  margin:24px 15px 0 20px;
}
