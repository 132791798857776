/*!
 *
 *  *
 *  *  Copyright (C) THL A29 Limited, a Tencent company. All rights reserved.
 *  *  SPDX-License-Identifier: Apache-2.0
 *  *
 *
 */

.top{
  width:100%;
  height:402px;
  background-color:#06163d;
  background-image: url(/assets/imgs/main.jpg);
  background-position: center;
  background-repeat: no-repeat;
  overflow: hidden;
  .top_info{
    width:1302px;
    margin:109px auto 0;
    .top_info_title{
      font-size: 30px;
      font-weight: bold;
      color: #38F4F9;
    }
    .top_info_search{
      width:604px;
      height:40px;
      overflow: hidden;
      display: flex;
      margin-top:37px;
    }
    .top_info_search_input{
      width:540px;
      padding:0 12px;
      background-color: white;
      input{
        width:526px;
        height:40px;
        border:0;
        outline: none;
        font-size:14px;
        color:#333;
        padding: 0;
      }
    }
    .top_info_search_bt{
      width: 64px;
      height: 40px;
      background-color: #2984EF;
      background-image: url(/assets/svgs/search.svg);
      background-position: center;
      background-repeat: no-repeat;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
    .top_info_line{
      margin-top:32px;
      width:64px;
      height: 8px;
      background: #2984EF;
    }
  }
}
.statistics{
  height:440px;
  width:1302px;
  margin: 34px auto 0;
  display: flex;
  justify-content: space-between;
}
.new_block{
  width:1302px;
  height:220px;
  background-color: white;
  margin:20px auto;
  overflow: hidden;
}
.news{
  display: flex;
  justify-content: space-between;
  width: 1302px;
  margin:32px auto;
  padding-bottom: 50px;
}


.footer{

width: 100%;
 
 
background-color: #fff;
display: flex;
flex-flow: row;
justify-content:center;
align-items: center;

height: 40px;
a{
  color: #0a0a0a;margin: 0 3px;
}

}