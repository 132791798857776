/*!
 *
 *  *
 *  *  Copyright (C) THL A29 Limited, a Tencent company. All rights reserved.
 *  *  SPDX-License-Identifier: Apache-2.0
 *  *
 *
 */

.chain{
  padding-top:50px;
  .title{
    font-size: 24px;
    font-weight: bold;
    color: #333333;
    margin-bottom:30px;
    text-align: center;
  }
  .subtitle{
    font-size: 18px;
    color: #333333;
    text-align: center;
    margin-bottom:30px;
  }
  :global(.tea-form-operate){
    text-align: center;
  }
}
