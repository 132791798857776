/*!
 *
 *  *
 *  *  Copyright (C) THL A29 Limited, a Tencent company. All rights reserved.
 *  *  SPDX-License-Identifier: Apache-2.0
 *  *
 *
 */

.box{
  width:638px;
}
.more{
  font-size: 14px;
  font-weight: 400;
  color: #2B82E8;
}
.list{
  background-color: white;
  padding: 0 24px;
  margin-top:10px;
  height:756px;
}
.item{
  height:64px;
  padding:5px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  border-bottom: 1px solid #DDD;
  &:last-child{
    border-bottom: 0;
  }
}
.title{
  font-size: 16px;
  font-weight: 400;
  color: #2B82E8;
}
.info{
  display: flex;
  justify-content: space-between;
}
.left{
  display: flex;
  align-items: center;
  flex: 1;
}
.path,.name{
  font-size: 14px;
  font-weight: 400;
  color: #333333;
}
.path{
  white-space: nowrap;
  margin-right: 8px;
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.name{
  margin-left:8px;
  flex:1;
  max-width: 240px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.time{
  font-size: 14px;
  font-weight: 300;
  color: #999999;
  width:130px;
  white-space: nowrap;
  text-align: right;
}
.point{
  height:0;
  width:0;
  border-left: 10px solid #DDDDDD;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  position: relative;
  &::after{
    content:'';
    border-left: 4px solid white;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    position:absolute;
    top:-5px;
    left:-10px;
  }
}
